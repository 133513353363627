import cloud from './img/cloud.svg';
import {Container,Row,Col,Button,Form, Ratio} from "react-bootstrap";
import { Marginer } from "./marginer";
import AwaisImg from "./img/Awais.png";
import Front from "./img/front.png";
import FrontMiddle from './img/Front3.png';
import slider1 from "./img/Architecture1.png";
import slider2 from "./img/Architecture2.png";
import slider3 from "./img/Architecture3.png";
import right from "./img/Group239.svg";
import left from "./img/Group 239 left.svg";
import HasnainImg from "./img/Hasnain.jpg";
import { useState} from 'react';
import axios from 'axios';
import ReCAPTCHA from 'react-google-recaptcha';
import {SocialFollow} from "./Social";
import { Slide } from 'react-slideshow-image';
import { Tab,Nav} from 'react-bootstrap';
import './index.css';  
function Home(){
    const url="https://www.enviree-data.com/restAPI/send_request.php";
    const[data,setData]= useState({
        f_name:"",
        l_name:"",
        email:"",
        country:"",
    });
    const [userinfo, setUserInfo] = useState({
        checkbox: [], 
        response: [],
    });
    const handleChange=(e)=>{
        const { value, checked } = e.target;
        const { checkbox } = userinfo;
        if (checked) {
            setUserInfo({
              checkbox: [...checkbox, value],
              response: [...checkbox, value],
            });
        }
        else {
            setUserInfo({
              checkbox: checkbox.filter((e) => e !== value),
              response: checkbox.filter((e) => e !== value),
            });
        }
    };      
    function handleSubmit(e){    
        e.preventDefault();
        var newval=localStorage.getItem('value');
        console.log('value is',newval);
        if(newval===null){ 
            alert("Please Verify that you are human!")   
        }
        else{
        axios.post(url,{
            response:userinfo.response,
            f_name:data.f_name,
            l_name:data.l_name,
            email:data.email,
            country:data.country
        },{ headers:{
            'Content-Type': 'application/x-www-form-urlencoded'
        }})
        .then(res=>{
            console.log("result is ",res.data)
            if(res.data==="Success"){
                alert("Your Response Submitted");
                window.location.reload();
            }
            else{
                alert("Something went wrong");
            }
        })
      }
    }
    //  let state = {
    //     selectedIndex: 0
    //   };
    // const handleSelect = (index) => {
    //     this.setState({ selectedIndex: index });
    //   };    
    function handle(e){
        const newdata={...data}
        newdata[e.target.id]=e.target.value;
        setData(newdata);
        console.log("data: ",newdata);
    }
    const Captcha = () => {
        function onChange(value) {
          console.log('Captcha value:', value);
          localStorage.setItem('value',value);
        }  return (
          <div className="App">
            <ReCAPTCHA
              sitekey="6Le-KBAgAAAAAIJziTn4ZgV4_QsHkDpR9UV_7kTd"
              onChange={onChange} 
            />
          </div>
        );
      };
      window.localStorage.clear();
    return(
        <Container fluid>
            <Row className='slider'>
                <Slide autoplay={true} indicators={true} arrows={false}>
                    <div className="each-slide-effect">
                    <Container>
                    <Row> 
                        <Col xs={12} md={5} xl={5} xxl={5}><p className='slide-heading'>Solutions For</p>
                        <p className='slide-txt'>AI Machine Learning and Advanced Analytics</p>
                        </Col>
                        <Col><img className='img-slider' src={Front} alt=''></img></Col>
                    </Row>
                    </Container>
                    </div>
                    <div className="each-slide-effect">
                    <Container>
                    <Row> 
                        <Col xs={12} md={5} xl={5} xxl={5}><p className='slide-heading'>Solutions For</p>
                        <p className='slide-txt'>Web Development</p>
                        </Col>
                            <Col><img className='img-slider' src={FrontMiddle} alt=''></img></Col>
                    </Row>
                    </Container>
                    </div>
                </Slide>
            </Row>
        <Container>
        <Marginer direction="Horizontal" margin="4rem"/>
        <Row md={2}>
        <Col xs={12} md={12} lg={6}>
            <div className="box-div">
            <h1 className="main-heading-1">Digital Business is our Passion</h1>
                <p className="paragraph">We support you professionally and with foresight in the challenges posed by technological change and progress. In doing so, we always focus on one thing - people. And thus your employees, your team. We are your partner at eye level. Strong and at your side, instead of from above. In this way, we develop needs-oriented and pragmatic solutions tailored to your situation so that your company can benefit from the game-changing leverages caused by AI, machine learning, and advanced analytics.
                </p>
            </div>
        </Col>
        <Col xs={12} md={12} lg={6}>
            <Ratio aspectRatio={21*3}>
                  <iframe className="video-link" src={'https://www.youtube.com/embed/F0RW1-oNLVg'}
                        allow="autoplay; clipboard-write; encrypted-media;"
                        title="Embedded youtube"
                    ></iframe>
            </Ratio>
        </Col>
        </Row>
        <Marginer direction="vertical" margin="5rem" />
        <Row xs="auto" md={1}>
            <div className="bttn">
                <h2>Our Services - Think Innovative</h2>
                <p className='services-para'>If you are a customer of Enviree then You are working with well-trained professionals, experts who have a passion for what they do and who know how to communicate. We take pride in taking responsibility and being accountable. If you need us, we are there for you. Uncomplicated, on short notice, we can offer services with competitive prices and round the clock.</p>
                  <Tab.Container id='left-tabs-example' defaultActiveKey="first">
                  <Row xs="auto" lg={2}>
                    <Col className='col-p' xs={12} md={12}>
                        <Nav variant='pills' className='tabs-link'>
                            <Nav.Item className='nav-item'>
                                <Nav.Link eventKey="first" className='navlink'>360° Business Consultancy
                                  <img src={right} alt="arrow"/>
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item className='nav-item'>
                                <Nav.Link eventKey="second" className='navlink'>D365 F&O Factsheet
                                    <img src={right} alt="arrow"/>
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item className='nav-item'>
                                <Nav.Link eventKey="Third" className='navlink'>Data Visualization
                                    <img src={right} alt="arrow"/>
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item className='nav-item'>
                                <Nav.Link eventKey="four" className='navlink'>Web Development
                                    <img src={right} alt="arrow"/>
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item className='nav-item'>
                                <Nav.Link eventKey="fifth" className='navlink'>Data Centric Products
                                    <img src={right} alt="arrow"/>
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item className='nav-item'>
                                <Nav.Link eventKey="six" className='navlink'>Predictive Analytics
                                    <img src={right} alt="arrow"/>
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item className='nav-item'>
                                <Nav.Link eventKey="seven" className='navlink'>Devops
                                    <img src={right} alt="arrow"/>
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item className='nav-item'>
                                <Nav.Link eventKey="eight" className='navlink'>D365 F&O Data Transformation
                                    <img src={right} alt="arrow"/>
                                </Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </Col>
                    <Col>
                    <Tab.Content>
                    <Tab.Pane eventKey="first">
                    <div className='para-div'>
                    <h5>360° Business Consultancy</h5>
                    <p className="paragraph">We can lead you toward a future where Business Anywhere is the norm, where every encounter is intelligent and frictionless, every process is totally automated and paperless, and everyone is prepared for whatever the future holds.</p>
                    <button className='buttn'>Download<img src={cloud} className='cloud-img' alt="img"></img>
                    </button>
                    </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="second">
                    <div className='para-div'>
                    <h5>D365 F&O Factsheet</h5>
                    <p className="paragraph">Enviree's key expertise is Microsoft Dynamics ERP, and we've worked with Dynamics for more than 10 years. Our team possesses the project management and technical expertise required to complete crucial business technology deployments. </p>
                    <p className="paragraph">Being a full-stack Microsoft partner and a users of the Microsoft Dynamics 365 suite, we can make an impact on every aspect of your firm.
Both on-site and remote Microsoft Dynamics training, implementations, upgrades, and support are areas in which we are skilled.</p>
                    <button className='buttn'>Download<img src={cloud} className='cloud-img' alt="img"></img></button>
                    </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="Third">
                    <div className='para-div'>
                    <h5>Data Visualization</h5>
                    <p className="paragraph">Utilize our dynamic data visualization solutions, which are based on a contemporary, adaptable infrastructure, to gain business insights driven by data.</p>
                    <p className="paragraph">Dashboarding is another name for data visualization, which is often referred to as data exploration or ad hoc business intelligence. Due to the straightforward manner in which complex data is presented in recent years, the data visualization sector has attracted a lot of attention.</p>
                    
                    <button className='buttn'>Download<img src={cloud} className='cloud-img' alt="img"></img></button>
                    </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="four">
                    <div className='para-div'>
                    <h5>Web Development</h5>
                    <p className="paragraph">For companies of all sizes, having a strong, credible online presence is crucial in today's digital environment. The website design and development services offered by Enviree may assist businesses in developing a website with a classic appearance, an easy-to-use interface, and accessibility from any location. </p>
                    <p className="paragraph">Businesses have the chance to expand online and attract more customers when they have a top-notch website and committed customer service behind it.</p>
                    <button className='buttn'>Download<img src={cloud} className='cloud-img' alt="img"></img></button>
                    </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="fifth">
                    <div className='para-div'>
                    <h5>Data Centric Products</h5>
                    <p className="paragraph">Advice from experts (Business Blueprint, Strategy, Technology and other business sectors)</p>
                    <p className="paragraph">Uncertain about how to begin while looking to grow your company? Want assistance with organising or carrying out your next project? We'll be your guides. Any firm can advance with minor tweaks, but in today's fast changing environment, constructing for the future requires taking calculated risks and wise judgement calls.</p>
                    <button className='buttn'>Download<img src={cloud} className='cloud-img' alt="img"></img></button>
                    </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="six">
                    <div className='para-div'>
                    <h5>Predictive Analytics</h5>
                    <p className="paragraph">Predictive analytics to a new level of proactivity. With Enviree on board, the organization can change its attention from queries like "What happened and why?" to more insightful and useful ones like "What will happen next?"</p>
                    <p className="paragraph">Better yet, ask yourself, "What's the best that could happen?" Our prediction models will assess all the data that are available using various algorithms and assist in creating accurate forecasts of various scenarios for your company.</p>
                    <button className='buttn'>Download<img src={cloud} className='cloud-img' alt="img"></img></button>
                    </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="seven">
                    <div className='para-div'>
                    <h5>Devops</h5>
                    <p className="paragraph">With our DevOps Services, you can hasten the release management cycle, boost efficiency, and accelerate cloud operations and workloads while lowering costs. Improve throughput by accelerating time to launch and realising robust systems. </p>
                    <p className="paragraph">DevOps Consulting Services are offered by committed teams at Enviree to assist your IT department in advancing the current project and implementing DevOps best practises. Our DevOps Services promote a "Everything as Code" mentality that unifies cloud operations.</p>
                    <button className='buttn'>Download<img src={cloud} className='cloud-img' alt="img"></img></button>
                    </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="eight">
                    <div className='para-div'>
                    <h5>D365 F&O Data Transformation</h5>
                    <p className="paragraph">By integrating your customers and business with the newest CRM and ERP solutions, you can overcome the issues of today. With the aid of sophisticated business apps from Dynamics 365, you can manage every aspect of your company and produce better outcomes.</p>
                    <p className="paragraph">Commerce, Finance, Supply Chain Management, and Human Resources are the four main areas of D365 Finance and Operations modules that Enviree offers advisory, implementation, and managed services for.</p>
                    <button className='buttn'>Download<img src={cloud} className='cloud-img' alt="img"></img></button>
                    </div>
                    </Tab.Pane>
                    </Tab.Content>
                    </Col>
                  </Row>
                  </Tab.Container>
            </div>
        </Row>
        <Row xs="auto" md={1}>
            <div className="bttn">
                <h2>Data Centric Products</h2>
                <p className='services-para'>We support you professionally and with foresight in the challenges posed
                  by technological change and progress.In doing so, we always focus on one thing-people. 
                  And thus your employees, your team. We are your partner at eye level.</p>
                  <Tab.Container id='left-tabs-example' defaultActiveKey="first">
                    <Row xs="auto" lg={2}>
                        <Col>
                        <Tab.Content>
                            <Tab.Pane eventKey="first">
                                <div className='para-div1'>
                                    <h5>Enviree Retail Analytics</h5>
                                    <p className="paragraph">Few industries have as straight a line from data to profit as retail. Being able to learn from and utilize your data is essential to success in a variety of areas, including inventory optimization, client segmentation, demand trends, and market patterns.</p>
                                    <p className="paragraph">To combine all of your data into real-time insights that drive efficient business choices, Enviree provides the structure, the know-how, and the technology partners you need.</p>
                                    <button className='buttn'>Download<img src={cloud} className='cloud-img' alt="img"></img></button>
                                </div>
                            </Tab.Pane>  
                            <Tab.Pane eventKey="second">
                                <div className='para-div1'>
                                    <h5>Microsoft Dynamics 365 Warehouse</h5>
                                    <p className="paragraph">As a Microsoft Dynamics consultant, we have a passion for utilizing technology to address challenging business issues. Enviree was founded because we believed that by using technology, we could improve businesses.</p>
                                    <p className="paragraph">In order to assist companies like yours in streamlining operational procedures and embracing digital transformation, we provide a full range of Dynamics 365 services. We have Microsoft Dynamics 365 consultants available to help. Enviree offers unparalleled customer service and is a reputable Microsoft Dynamics 365 Partner.</p>
                                    <button className='buttn'>Download<img src={cloud} className='cloud-img' alt="img"></img></button>
                                </div>
                            </Tab.Pane>  
                            <Tab.Pane eventKey="Third">
                                <div className='para-div1'>
                                    <h5>Enviree ETL Framework</h5>
                                    <p className="paragraph">Enviree has experience creating and executing cutting-edge DWH and ETL solutions for banks, financial institutions, retail businesses, manufacturing and other industries. Using technologies that provide the most efficient business data analysis solutions, we can meet your strategic objectives thanks to our expertise.</p>
                                    <button className='buttn'>Download<img src={cloud} className='cloud-img' alt="img"></img></button>
                                </div>
                            </Tab.Pane>  
                            <Tab.Pane eventKey="four">
                                <div className='para-div1'>
                                    <h5>E-Commerce Cloud Data Warehouse</h5>
                                    <p className="paragraph">The best possible usage of your e-commerce solution is made possible by e-commerce consulting. But at Enviree, we go beyond a more limited definition of the digital user experience on your website. We assist you in managing all aspects of a customer's interaction with your business, from awareness to loyalty. The transformation you can create with the right technologies and tools will astound you.</p>
                                    <button className='buttn'>Download<img src={cloud} className='cloud-img' alt="img"></img></button>
                                </div>
                            </Tab.Pane>  
                        </Tab.Content>
                            </Col>
                            <Col md={12}>
                            <Nav variant='pills' className='tabs-link'>
                                    <Nav.Item className='nav-item'>
                                        <Nav.Link eventKey="first" className='navlink1'>Enviree Retail Analytics
                                        <img src={left} alt="arrow"/>
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className='nav-item'>
                                        <Nav.Link eventKey="second" className='navlink1'>Microsoft Dynamics 365 Warehouse
                                            <img src={left} alt="arrow"/>
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className='nav-item'>
                                        <Nav.Link eventKey="Third" className='navlink1'>Enviree ETL Framework
                                            <img src={left} alt="arrow"/>
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className='nav-item'>
                                        <Nav.Link eventKey="four" className='navlink1'>E-Commerce Cloud Data Warehouse
                                            <img src={left} alt="arrow"/>
                                        </Nav.Link>
                                    </Nav.Item>
                            </Nav>
                            </Col>
                        </Row>
                    </Tab.Container>
            </div>
        </Row>

        <Row md={1}>
            <h1 className="architectures">Architectures</h1>
        </Row>
        <Row className='slider'>
                <Slide autoplay={true} indicators={true} arrows={true}>
                    <div className="each-slide">
                    <Row> 
                            <Col><img  className='img-slider' src={slider1} alt=''></img></Col>
                            <Col><img className='img-slider' src={slider2} alt=''></img></Col>
                    </Row>
                    </div>
                    <div className="each-slide">
                    <Row> 
                            <Col><img className='img-slider' src={slider2} alt=''></img></Col>
                            <Col><img className='img-slider' src={slider3} alt=''></img></Col>
                    </Row>
                    </div>
                    <div className="each-slide">
                        <Row> 
                            <Col><img className='img-slider' src={slider3} alt=''></img></Col>
                            <Col><img className='img-slider' src={slider1} alt=''></img></Col>
                    </Row>
                    </div>
                </Slide>
            </Row>
        <Row md={1}>
            <h1 className="heading-senior">Senior Executives</h1>
        </Row>
        <Row md={2}>
        <Col xs="auto">
        <div className="box-div1">
                  <img className='img-div' src={AwaisImg}
                        width="250"
                        height="250"
                        frameBorder="0"
                        alt=""
                       />
            </div>
            <div className="owner-div1">
            <h5 className="owner-heading">Avais Ahmad Qarni - CEO.</h5>
                <p className="owner-paragraph">Experienced top-business intelligence consultant with the passion to transform companies to become digital and agile.
                </p>
            </div>
            <div className='social-follow'><SocialFollow/></div>
        </Col>
    
        <Col xs="auto">
        <div className="box-div1">
                  <img className='img-div' src={HasnainImg}
                         width="250"
                         height="250"
                        frameBorder="0"
                        alt=""
                       />
            </div>
            <div className="owner-div1">
            <h5 className="owner-heading">Hasnain Shabbir - CTO.</h5>
                <p className="owner-paragraph">Successful entrepreneur with addiction to new and emerging technologies.
                </p>
            </div>
            <div className='social-follow'><SocialFollow/></div>
        </Col>
        </Row> 
        <Row xs="auto" md={2} lg={2} className="Demo-row">
             <Col md={12} lg={5}>
                <div className="request-demo">
                    <h3>Schedule your Personal Demo</h3>
                    <Marginer direction="vertical" margin="1rem" />
                    <p className="paragraph">Sign up for a demo today, and not only will you receive a full unrestricted 
                    Enviree license, with all modules activated and our unique Customization 
                    Wizard for a full Data Warehouse Automation experience, but we will also do 
                    an online demo, install the solution across your data, connect Power BI and 
                    Excel dashboards and give you 1 half-day workshop at NO CHARGE. A full onboarding 
                    experience of Enviree is free for 30 days. </p>
                </div>   
             </Col>
             <Col md={0} lg={1}></Col>
             <Col md={12} lg={6}>
                <div className="service-form">
                <Form onSubmit={(e)=>handleSubmit(e)}>
                    <Row className="mb-3">
                        <Form.Group as={Col}>
                        <Form.Control className="form-input" type="text" placeholder="First Name" onChange={(e)=>handle(e)} value={data.f_name} id="f_name" />
                        </Form.Group>

                        <Form.Group as={Col}>
                        <Form.Control className="form-input" type="text" placeholder="Last Name" onChange={(e)=>handle(e)} value={data.l_name} id="l_name" />
                        </Form.Group>
                    </Row>
                    <Row className="mb-3">
                        <Form.Group as={Col}>
                        <Form.Control className="form-input" type="email" placeholder="Email Address *" onChange={(e)=>handle(e)} value={data.email} id="email"/>
                        </Form.Group>

                        <Form.Group as={Col}>
                        <Form.Control className="form-input" type="text" placeholder="Country" onChange={(e)=>handle(e)} value={data.country} id="country"/>
                        </Form.Group>
                    </Row>
                    <Form.Group className="mb-3" id="formGridCheckbox">
                        <Form.Label className="form-label1">Interested In:</Form.Label>
                        <Row xs="auto" className="form-row">
                            <Form.Check type="checkbox" onChange={handleChange} value="D365 F&O" label="D365 F&O"/>
                            <Form.Check type="checkbox" onChange={handleChange} value="AX" label="AX" />
                            <Form.Check type="checkbox" onChange={handleChange} value="D365 BC" label="D365 BC"/>
                            <Form.Check type="checkbox" onChange={handleChange} value="NAV" label="NAV"/>
                        </Row>
                    </Form.Group>
                    <Captcha/>
                    <Button className="button-form" variant="primary" type="submit">
                        Submit Request
                    </Button>
                    </Form>
                </div>
             </Col>
        </Row>
        <Row md={1}>
            <h1 className="heading-end">Useful Links</h1>
        </Row>
        <Row xs="auto" className="last_row">
        <Col> 
            <button className="btn2"> Videos </button>
        </Col>
        <Col>
            <button className="btn2" > Platform </button>
        </Col>
        <Col>
            <button className="btn2"> Customization FO (D365/AX) </button>
        </Col>
        <Col>
            <button className="btn2"> External Data Sources </button>
        </Col>
        </Row>
    </Container>
    </Container>
    );
}
export default Home;