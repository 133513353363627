import React from "react";
import {FacebookShareButton, WhatsappShareButton,TwitterShareButton} from "react-share"
import {FacebookIcon, WhatsappIcon,TwitterIcon ,  LinkedinIcon,LinkedinShareButton} from  "react-share"
import {Container , Segment} from "semantic-ui-react"
import styled from "styled-components";
import './index.css';
const ServicesContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items:center;
    justify-content:center;
`
export  function SocialFollow() {
    
  return (
      <div>
      <Container>
          <Segment>  
        <ServicesContainer>     
          <FacebookShareButton
          url = "https://enviree24.de/"
         quote = "subscribe"
         hashtag = "#react"
          >
              <FacebookIcon size={32}  round = {true} style={{marginleft:10}}>
              </FacebookIcon>
          </FacebookShareButton>
          < LinkedinShareButton
          url = "https://enviree-data.com/"
         quote = "subscribe"
         hashtag = "#react"
          >
              < LinkedinIcon size={32}  round = {true} style={{marginLeft:10}}>
              </ LinkedinIcon>
          </ LinkedinShareButton>
          <TwitterShareButton
          url = "https://enviree-data.com/"
         quote = "subscribe"
         hashtag = "#react"
          >
              <TwitterIcon size={32}  round = {true} style={{marginLeft:10}}>
              </TwitterIcon>
          </TwitterShareButton>
          <WhatsappShareButton
          title = "sharing"
          url = "https://enviree-data.com/"
          >
              <WhatsappIcon size={32}  round = {true} style={{marginLeft:10}}> </WhatsappIcon >
          </WhatsappShareButton>
          </ServicesContainer>     
          </Segment>
      </Container>
      </div>
  );
}
