import group92 from './img/Group 92.svg';
import './index.css';
import {Container,Row,Col} from "react-bootstrap";
function Aboutus(){
    return(
        <div>
            <Container>
            <Row className='show-grid1' style={{height:'40vh',borderBottom:'1px solid grey'}}>
                <div className='contact-heading'>
                   <h1>About Us</h1>
                   <p>Dummy Data</p>
                </div>   
            </Row>    
            <Row xs="auto" md={2}>
            <Col xs="auto">
                <div>
                    <h2>About Us</h2>
                    <p>Address: Dummy Address</p>
                    <p>phone: +00 000000000</p>
                    <p>Email:  <a href='info@somebody.com' style={{color:'#008848'}}>info@somebody.com</a></p>
                </div>
            </Col>
            <Col xs={12}>
            <div className="img-enviree">
                <img src={group92}
                height='430'
                width='500'
                alt="Logo 2"/>
            </div>
            </Col>
            </Row>
            </Container>
        </div>
    );
}
export default Aboutus;