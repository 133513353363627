import {Navbar,Nav,Container,Col} from 'react-bootstrap';
import {NavLink} from "react-router-dom";
import React from "react";
import logo from './img/logo.svg';
function Header(){
    return(
    <Navbar className='navbar' expand="lg" fixed='top'>
    <Container>
            <Navbar.Brand href="#home">
            <img
                src={logo}
                height="98"
                width="108"
                alt="Enviree logo"
            />
            </Navbar.Brand>
        <Col lg={2} xl={3} xxl={3}></Col>
            <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
            <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="d-flex justify-content-end">
                        <NavLink to="/" className='nav-link'>Home</NavLink>
                        <NavLink to="/data-platform-germany" className='nav-link' >Data Platform</NavLink>
                        <NavLink to="/cloud-services-germany" className='nav-link' >Cloud Services</NavLink>
                        <NavLink to="/website-development-germany" className='nav-link' >Website Development</NavLink>
                        <NavLink to="/contact" className='nav-link contact-us'>Contact</NavLink>
                </Nav>
            </Navbar.Collapse>
    </Container>
    </Navbar>
  );
}
export default Header;