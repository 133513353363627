// import group92 from './img/Group 92.svg';
import './index.css';
import {Container,Form,Button,Row, Col} from "react-bootstrap";
import { useState } from 'react';
import axios from 'axios';
import ReCAPTCHA from 'react-google-recaptcha';
function Contact(){ 
    const url="https://www.enviree24.de/restAPI/send_email.php";
    const[data,setData]= useState({
        name:"",
        email:"",
        comment:""
    })
    function handle(e){
        const newdata={...data}
        newdata[e.target.id]=e.target.value;
        setData(newdata);
        console.log("data: ",newdata);
    }
    function handleSubmit(e){    
        e.preventDefault(); 
        var newval=localStorage.getItem('value');
        console.log('value is',newval);
        if(newval===null){ 
            alert("Please Verify that you are human!")   
        }
        else{
        axios.post(url,{
            name:data.name,
            email:data.email,
            comment:data.comment
        },{ headers:{
            'Content-Type': 'application/x-www-form-urlencoded'
        }})
        .then(res=>{
            console.log("result is ",res.data)
            if(res.data==="Success"){
                alert("Your Response Submitted");
                window.location.reload();
            }
            else{
                alert("Something went wrong");
            }
        })
       }
    }
    const Captcha = () => {
        function onChange(value){
          console.log('Captcha value:', value);
          localStorage.setItem('value',value);
        } 
          return (
          <div className="App">
            <ReCAPTCHA
              sitekey="6Le-KBAgAAAAAIJziTn4ZgV4_QsHkDpR9UV_7kTd"
              onChange={onChange}
            />
          </div>
        );
      };
      window.localStorage.clear(); 
    return(
        <div>
            <Container>
            <Row className='show-grid' style={{height:'40vh',borderBottom:'1px solid grey'}}>
                <div className='contact-heading'>
                   <h1>Contact Us</h1>
                   <p>Have any questions? We'd love to hear from you</p>
                </div>   
            </Row>    
            <Row xs="auto" md={2}>
            <Col xs="auto">
                <div>
                    <h2>Contact Info</h2>
                    <p>Enviree Services GmbH </p>
                    <p>HRB 33757 </p>
                    <p>Pottgiesserstrasse 10, </p>
                    <p>45144, Essen </p>
                    <p>Landline:  +49201 50904323</p>
                    <p>Mobile:    +49176 31327333</p>
                    <p>Email:     <a href='info@enviree24.de' style={{color:'#008848'}}>info@enviree24.de</a></p>
                </div>
            </Col>
            <Col xs={12}>
                <div className="form">
                    <Form onSubmit={(e)=>handleSubmit(e)}>
                        <Form.Group className="mb-3">
                            <Form.Label>Name</Form.Label>
                            <Form.Control type="text" placeholder="Enter your name" required onChange={(e)=>handle(e)} id="name" value={data.name}/>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Email address</Form.Label>
                            <Form.Control type="email" placeholder="Enter email" required onChange={(e)=>handle(e)} id="email" value={data.email}/>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Comment</Form.Label>
                            <Form.Control as="textarea" rows={3} placeholder="Write a comment" required onChange={(e)=>handle(e)} id="comment" value={data.comment}/>
                        </Form.Group>
                        <Captcha/>
                        <Button className="btn-form" type="submit">Submit</Button>
                    </Form>
                </div>
            </Col>
            </Row>
            </Container>
        </div>
    );
}
export default Contact;