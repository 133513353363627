import {Container,Row,Col,Button,Form, Ratio} from "react-bootstrap";
import metadata from './img/metadata.png';
import deliver from './img/deliver BI.png';
import wizard from './img/wizard driven.png';
import Documentation from './img/documentation.png';
import checkicon from './img/check icon.svg';
import { useState} from 'react';
import axios from 'axios';
import ReCAPTCHA from 'react-google-recaptcha';
import './index.css';
import { Marginer } from "./marginer";
function Services(){
    // const url="http://localhost/projects/test/send_request.php";
    const url="https://www.enviree24.de/restAPI/send_request.php";
    const[data,setData]= useState({
        f_name:"",
        l_name:"",
        email:"",
        country:"",
    });
    const [userinfo, setUserInfo] = useState({
        checkbox: [],
        response: [],
    });
    const handleChange=(e)=>{
        const { value, checked } = e.target;
        const { checkbox } = userinfo;
        if (checked) {
            setUserInfo({
              checkbox: [...checkbox, value],
              response: [...checkbox, value],
            });
        }
        else {
            setUserInfo({
              checkbox: checkbox.filter((e) => e !== value),
              response: checkbox.filter((e) => e !== value),
            });
        }
    };      
    function handleSubmit(e){    
        e.preventDefault();
        var newval=localStorage.getItem('value');
        console.log('value is',newval);
        if(newval===null){ 
            alert("Please Verify that you are human!")   
        }
        else{
        axios.post(url,{
            response:userinfo.response,
            f_name:data.f_name,
            l_name:data.l_name,
            email:data.email,
            country:data.country
        },{ headers:{
            'Content-Type': 'application/x-www-form-urlencoded'
        }})
        .then(res=>{
            console.log("result is ",res.data)
            if(res.data==="Success"){
                alert("Your Response Submitted");
                window.location.reload();
            }
            else{
                alert("Something went wrong");
            }
        })
      }
    }
    function handle(e){
        const newdata={...data}
        newdata[e.target.id]=e.target.value;
        setData(newdata);
        console.log("data: ",newdata);
    }
    const Captcha = () => {
        function onChange(value) {
          console.log('Captcha value:', value);
          localStorage.setItem('value',value);
        }  return (
          <div className="App">
            <ReCAPTCHA
              sitekey="6Le-KBAgAAAAAIJziTn4ZgV4_QsHkDpR9UV_7kTd"
              onChange={onChange} 
            />
          </div>
        );
      };
      window.localStorage.clear(); 
    return(
     <Container>
        <Row className="show-grid" md={2}>
        <Col xs={12} md={12} lg={6}>
            <div className="box-div-service">
            <h1 className="main-heading">Set of Comprehensive Solutions from Microsoft Azure that 
            Turn Data into Actionable Insights.</h1>
            <Marginer direction="vertical" margin="2rem" />
                <p className="paragraphs1">Build a single source of truth for all format of structured, unstructured, 
                or streaming data to drive transformative solutions e.g. Data Engineering, AI/ML, and real-time BI Analytics.
                Take advantages of the performance, flexibility, and security of fully managed Azure Data Platform to get started with ease.
                </p>
            </div>
        </Col>
        <Col xs={12} md={12} lg={6}>
        <Ratio aspectRatio={21*3}>
                        <iframe className="video-link" src={'https://www.youtube.com/embed/F0RW1-oNLVg'}
                        allow="autoplay; clipboard-write; encrypted-media;"
                        title="Embedded youtube"
                        ></iframe>
            </Ratio>
        </Col>
        </Row> 
        <Marginer direction="vertical" margin="5rem" />
        <Row>
            <div className="customize-heading1">
                <h4 className="customize">What are the Key Challenges to implement Data Platform Solutions </h4>  
            </div>
        </Row>
        <Row xs='auto' md={12} lg={3}>
                <Col xs="auto">
                    <div className="rect-text">
                        <h4>Data Silos</h4>
                        <p>Multiple data sources spread across the organization makes it difficult to find and share data.</p>
                    </div>
                </Col>
                <Col xs="auto">
                    <div className="rect-text">
                        <h4>Data Explosion</h4>
                        <p>Unable to process and derive insights from the huge volume, variety and velocity of incoming data.</p>
                    </div>
                </Col>
                <Col xs="auto">
                    <div className="rect-text">
                        <h4>AI/ML Adoption</h4>
                        <p>Missed business opportunities due to slow adoption of AI/ML and real time analytics.</p>
                    </div>
                </Col>
        </Row>
         <Row>
                <div className="customize-heading">
                    <h1 className="customize">Areas Of Expertise </h1> 
                </div>  
        </Row>
        
        <Row xs="auto">
                    <div className="services_rectangle">
                        <img  className="img-rec" src={metadata}alt="metadata"></img>
                        <h4 className="service-heading">Metadata based modeling</h4>
                        <p className="service-paragraph">Enviree Retail Analytics connects to Microsoft Dynamics FO (on-premises or in the cloud)
                         and reads AOT/Object Metadata. Detailed information about... Tables, 
                         Columns, Table Relations, Indexes, Enums, and Translations are creating
                          the Metadata model. Object templates are filled with Metadata and generate 
                          scripts that create a data warehouse. Every Dynamics FO project is different. 
                          As Enviree Retail Analytics uses Metadata all ERP changes can be easily transferred to the BI project.</p>
                    </div>
                    <div className="services_rectangle">
                    <img className="img-rec" src={wizard}alt="wizard"></img>
                        <h4 className="service-heading">Wizard driven development</h4>
                        <p className="service-paragraph">A five-step wizard-driven process (start, select tables, select columns, manage 
                            dimensions and manage measures) will drive your team to accomplish... customizations of data warehouse accurately 
                            and really very fast. With proper training, a person with Dynamics knowledge can deliver most tasks 
                            without prior knowledge of data warehouse concepts.
                        </p>
                    </div>
                    <div className="services_rectangle">
                    <img className="img-rec" src={deliver} alt="deliver"></img>
                        <h4 className="service-heading">Deliver Bi project by yourself</h4>
                        <p className="service-paragraph">The same tool that our consultants use to build a core data warehouse is available to users. 
                            More than 150 tables that create more than 100 dimensions and fact with approximately 1500 measures 
                            are a starting point. Customizing existing content or adding new is easy. Add external source by Power BI 
                            (!) generated power query, paste it to SSIS package that will bring data to SQL. These tables are a first-class 
                            citizens in wizard-driven development. The best of two words – the flexibility of Power BI and 
                            scalability of SQL.
                        </p>
                    </div>
                    <div className="services_rectangle">
                    <img className="img-rec" src={Documentation}alt="Documentation"></img>
                        <h4 className="service-heading">Documentation with a click of a mouse</h4>
                        <p className="service-paragraph">The same model that generates all scripts is used to generate 100+ page documentation.
                         It includes the latest technical details about architecture, setup (data sourc…. companies, global dimensions), source 
                         of dimensions, attributes, formulas that create a measure, and time intelligence. The last section includes Top table report 
                         by data size and Top procedures by exaction time. Our effort – 120 man-days. Your effort – one click and 45 seconds.</p>
                    </div>
            </Row>
            <Row xs="auto" className="button-rectangle">
                    <Col xs={12} lg='5' md={10}>
                       <h3 className="bttn-rec-text">Click below for more information and how-to-videos on:</h3>
                    </Col>
                    <Col xl={1} xxl={2}></Col>
                    <Col xs='auto'>
                       <div className="button-rect"> 
                            <button className="rect-bttn">Customization FO (AX)</button>
                            <button className="rect-bttn">Customization BC (NAV)</button>
                        </div>
                    </Col>  
            </Row>
            <Row>
                <div className="customize-heading">
                    <h1 className="customize"> Azure Data Platform Development Journey </h1>
                    <p>Our clients realize value from the Azure Data Platform development at a very early stage in their journey.</p>
                </div>
            </Row>
            <Row xs='auto' md={12} lg={3}>
                <Col xs="auto">
                    <div className="rect-text1">
                        <h4>Runs on Latest Microsoft Technology</h4>
                            <p> 
                                Enviree Retail Analytics is a fully optimized Business Intelligence solution. On top of the Data Warehouse users can
                                choose the Tabular Model or OLAP Cubes. It incorporates incremental update, partition-based processing, 
                                Columnstore index, etc.
                            </p>
                    </div>
                </Col>
                <Col xs="auto">
                    <div className="rect-text1">
                        <h4>100% open SQL scripts for modifications</h4>
                        <p>
                            DWA generates 1 million rows of SQL scripts (T-SQL, XML) that are 100% open for any modification. 
                            Sections separated by placeholders structure all SQL scripts. Because of this users have control
                            and understanding when doing manual customizations.
                        </p>
                    </div>
                </Col>
                <Col  xs="auto">
                    <div className="rect-text1">
                        <h4>Join all data sources (Microsoft Dynamics + any other)</h4>
                        <p>
                            To integrate external data sources you first integrate SSIS packages to Enviree Retail Analytics Data Warehouse. 
                            Then you create new BI structures (facts, dimensions and cubes) with the Enviree Retail Analytics development wizard.
                        </p>
                    </div>
                </Col>
        </Row>
        <Row xs="auto" md={2} lg={2} className="Demo-row">
             <Col md={12} lg={5}>
                <div className="request-demo"> 
                    <h3 className="request-heading">Request Demo</h3>
                    <p>Sign up for a demo today, and not only will you receive a full unrestricted 
                    Enviree License, with all modules activated and our unique Customization 
                    Wizard for a full Data Warehouse Automation experience, but we will also do 
                    an online demo, install the solution across your data, connect Power BI and 
                    Excel dashboards and give you 1 half-day workshop at NO CHARGE. A full onboarding 
                    experience of Enviree Retail Analytics is free for 30 days.</p>
                </div>   
             </Col>
             <Col md={0} lg={1}></Col>
             <Col md={12} lg={6}>
                <div className="service-form">
                <Form onSubmit={(e)=>handleSubmit(e)}>
                    <Row className="mb-3">
                        <Form.Group as={Col}>
                        <Form.Control className="form-input" type="text" placeholder="First Name" onChange={(e)=>handle(e)} value={data.f_name} id="f_name" />
                        </Form.Group>

                        <Form.Group as={Col}>
                        <Form.Control className="form-input" type="text" placeholder="Last Name" onChange={(e)=>handle(e)} value={data.l_name} id="l_name" />
                        </Form.Group>
                    </Row>

                    <Row className="mb-3">
                        <Form.Group as={Col}>
                        <Form.Control className="form-input" type="email" placeholder="Email Address *" onChange={(e)=>handle(e)} value={data.email} id="email"/>
                        </Form.Group>

                        <Form.Group as={Col}>
                        <Form.Control className="form-input" type="text" placeholder="Country" onChange={(e)=>handle(e)} value={data.country} id="country"/>
                        </Form.Group>
                    </Row>
                    <Form.Group className="mb-3" id="formGridCheckbox">
                        <Form.Label className="form-label1">Interested In:</Form.Label>
                        <Row xs="auto" className="form-row">
                            <Form.Check type="checkbox" onChange={handleChange} value="D365 F&O" label="D365 F&O"/>
                            <Form.Check type="checkbox" onChange={handleChange} value="AX" label="AX" />
                            <Form.Check type="checkbox" onChange={handleChange} value="D365 BC" label="D365 BC"/>
                            <Form.Check type="checkbox" onChange={handleChange} value="NAV" label="NAV"/>
                        </Row>
                    </Form.Group>
                    <Captcha/>
                    <Button className="button-form" variant="primary" type="submit">
                        Submit Request
                    </Button>
                    </Form>
                </div>
             </Col>
        </Row> 
        <div className="experience-rect">
            <Row xs="auto" md={3}>
                    <Col xs="auto" >
                        <img className="check-img" src={checkicon} alt="check icon"></img>
                        <h4 className="experience-heading">Superior out of the box BI developed, especially for Microsoft Dynamics.</h4>
                        <div className="line"></div>
                    </Col>
                    <Col xs="auto">
                        <img className="check-img" src={checkicon} alt="check icon"></img>
                        <h4>Unparalleled flexibility that allows your team to be in control of the BI project.</h4>
                        <div className="line"></div>
                    </Col>
                    <Col xs="auto">
                        <img className="check-img" src={checkicon} alt="check icon"></img>
                        <h4>Experiences from 1.000 projects in many industries and company sizes.</h4>
                        <div className="line"></div>
                    </Col>
            </Row>
        </div>
        <Row md={1}>
            <h1 className="heading-end1">Useful Links</h1>
        </Row> 
        <Row xs="auto" className="last_row">
            <Col> 
                <button className="btn2"> Videos </button>
            </Col>
            <Col>
                <button className="btn2" > Platform </button>
            </Col>
            <Col>
                <button className="btn2"> Customization FO (AX) </button>
            </Col>
            <Col>
                <button className="btn2"> POC on Tier 2 </button>
            </Col>
            <Col>
                <button className="btn2"> External Data Sources </button>
            </Col>
        </Row>
     </Container>
    );    
}
export default Services;