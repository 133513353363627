import {Row,Col,Container} from 'react-bootstrap';
import {SocialFollow} from "./Social";
import {NavLink} from "react-router-dom";
import './index.css';
function Footer(){
    return(
     <div className='footer'> 
        <Container>
            <Row className="justify-content-md-center"> 
                <Row className='row-first'>
                    <Col md={12} lg={4} xl={5} className='foot-heading'>
                        <h3>Enviree</h3>
                        <p className='footer-text'>Bring Learner and Developers Together.</p>
                        <div className='social-footer'><SocialFollow/></div>
                    </Col>
                    <Col xs='auto' className='foot-col' md={4} lg={2}>
                        <h3>Who We Are?</h3>
                        <p>Our Services</p>
                        {/* <p>Data Platform</p>
                        <p>Cloud Services</p>
                        <p>Web Development</p>
                        <p>Others</p> */}
                        <p><NavLink to="/data-platform-germany" className='nav-link' >Data Platform</NavLink></p>
                        <p><NavLink to="/cloud-services-germany" className='nav-link' >Cloud Services</NavLink></p>
                        <p><NavLink to="/website-development-germany" className='nav-link' >Website Development</NavLink></p>
                        <p><NavLink to="/contact" className='nav-link'>Contact</NavLink></p>
                    </Col>
                    <Col xs='auto' className='foot-col' md={4} lg={2}>
                        <h3>Support</h3>
                        <p>Careers</p>
                        <p>Privacy Policy</p>
                        <p>Terms & Condition</p>
                        <p>Contact</p>
                    </Col>
                    <Col xs='auto' className='foot-col' md={4} xl={3}>
                        <h3>Contact Info</h3>
                        <p>Enviree Services GmbH</p>
                        <p>HRB 33757</p>
                        <p>Pottgiesserstrasse 10, </p>
                        <p>45144 Essen,</p>
                        <p>NRW, Germany</p>
                        <p>info@Enviree24.de</p>
                        <p>+49201 50904323</p>
                    </Col>
                </Row>
                <Row className='row-end'>
                    <div className='rights-text'>
                        <p>© Enivree 2023. All Rights Reserved</p>
                    </div>
                </Row>
            </Row>
        </Container>
    </div>  
    );
}
export default Footer;