import Header from "./Header";
import Footer from "./Footer";
import Contact from "./contact";
import Home from "./Home";
import Aboutus from "./aboutus";
import Services from "./service-1";
import Services2 from "./service-2";
import Services3 from "./service-3";
import Services4 from "./service-4";
import Services5 from "./service-5";
import Services6 from "./service-6";
import Webdevelopment from "./web_development";
import Appdevelopmet from "./app_developmet";
import {BrowserRouter as Router,Routes,Route} from "react-router-dom";
import ScrollToTop from "./scrolltotop";
function App() {
  return (
     <div>
      <Router> 
       <Header/>
       <ScrollToTop>
        <Routes>
          <Route path="/" element={<Home />}/>
          <Route path="/aboutus" element={<Aboutus />}/>
          <Route path="/data-platform-germany" element={<Services />}/>
          <Route path="/cloud-services-germany" element={<Services2 />}/>
          <Route path="/website-development-germany" element={<Services3/>}/>
          <Route path="/contact" element={<Contact />}/>
        </Routes>
        </ScrollToTop>
        <Footer/>
      </Router>
     </div>
  );
}
export default App;