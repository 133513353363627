import {Container,Row,Col} from "react-bootstrap";
import { Tab,Nav} from 'react-bootstrap';
import banner from './img/Templets/banner3.png';
import coding from './img/Templets/coding.png';
import web from './img/Templets/web.png';
import right from "./img/Group239.svg";
import website from './img/Templets/website.png';
import checkicon from './img/check icon.svg';
import { Marginer } from "./marginer";
import './index.css';
function Services3(){ 
        return(
         <Container fluid>
            <div className="banner-row">
            <Container>
                <Row>
                <Col xs={12} md={5} xl={5} xxl={6} className="banner-col">
                    <p className='development-head'>Website Development</p>
                    {/* <p className='slide-txt'>By Professionals</p> */}
                </Col>
                <Col md={7} lg={5}>
                   <img className="img-slider" src={banner} alt="banner"></img>
                </Col>
                </Row>
            </Container>
            </div>
            <Container>
            <Marginer direction="vertical" margin="2rem" />
            <Row>
                <Col lg={7}>
                    <img className="img-slider" src={web} alt="web"></img>
                </Col>
                <Col className="box">
                    <h1 className="website-heading">Website Development by Professionals</h1>
                    <p className="paragraph">
                        Enviree Web Development is a passionate team of professionals who are experts in web development. 
                        Our certified web developers in Germany has years of experience in developing websites for businesses 
                        of all sizes. Our web developers have the skills and knowledge to create custom websites that are 
                        responsive, user-friendly, and engaging.
                    </p>    
                    <p className="paragraph">
                        If you're looking for a team of web development experts who will help you grow your 
                        business, contact Enviree Web Development today. We'll be happy to discuss your project 
                        requirements and provide you with a free quote.
                    </p>
                </Col>
            </Row>
            <Marginer direction="vertical" margin="2rem" />
            <Row>
                <Col className="box">
                    <h1 className="website-heading">WEB ADVANCEMENT FROM THE SPECIALISTS</h1>
                    <p className="paragraph">
                        Our team of specialist web developers is constantly keeping up with web development 
                        trends, so you can rest assured that your website will be ahead of the curve. 
                        We use cutting-edge technologies to create custom solutions that meet your specific 
                        needs. If you're looking for a team of web development experts who can do your project 
                        flawlessly, contact us at Enviree. We'll guide you about our services and how you 
                        can achieve your goals with us.
                    </p>
                </Col>
                <Col md={10} lg={7}>
                    <img className="img-slider" src={coding} alt="coding"></img>
                </Col>
            </Row>
            <Marginer direction="vertical" margin="2rem" />
            <Row>
                <Col md={12} lg={7}>
                    <img className="img-slider" src={website} alt="web"></img>
                </Col>
                <Col className="box">
                    <h1 className="website-heading">WE MAKE WEBSITE COME TO LIFE</h1>
                    <p className="paragraph">
                        The internet has become a staple in daily life and business. 
                        The web is changing daily, and new technologies make it easier 
                        for businesses to reach their target audiences. Enviree Web Development 
                        is at the forefront of these changes, and we work hard to ensure your 
                        website is up-to-date and responsive to the ever-changing web landscape.
                    </p>    
                    <p className="paragraph">
                        We love turning complex problems into simple, beautiful solutions that 
                        our clients can use to grow their businesses.
                    </p>
                </Col>
            </Row>
            <Marginer direction="vertical" margin="4rem" />
            <Row>
                    <div className="customize-heading1">
                        <h2 className="customize">Our Website development Process</h2>
                        <p>Here at Enviree, we have a well-defined process for developing websites that ensures our clients receive high-quality products.</p>
                    </div>  
            </Row>
            <Row xs="auto">
                    <div className="services_rectangle_web">
                        <h4 className="service-heading">Design Ideation & Prototyping</h4>
                        <p className="service-paragraph">First, we meet with the client to discuss their needs and goals for the website. We then move on to the planning 
                        phase, where we map out the structure and layout of the site. The design phase is when you create the website's visual components. Our web designers 
                        take a unified approach to the effort, considering user acquisition and retention along the way. 
                        The design is then tested, and a storyboard is created and shared with the clients.</p>
                    </div>
                    <div className="services_rectangle_web">
                        <h4 className="service-heading">User Experience Creation</h4>
                        <p className="service-paragraph">
                            Our web development team starts development once we've received the wireframe and sitemap verified 
                            from our customers. Based on the requirements we get, we'll create a marvellous-looking design theme. 
                            Once it's confirmed aesthetically, our coding team works on all the most profound facets of your digital 
                            presence to bring it to life.
                        </p>
                    </div>
                    <div className="services_rectangle_web">
                        <h4 className="service-heading">Coding</h4>
                        <p className="service-paragraph">
                            Once the design is finalized, our expert web developers begin coding up designs by coding the back-end and front-end. 
                            Front-end specialists code the HTML for website pages, adding animation and more complex features with frameworks like 
                            Angular, React, Mateo & Vue (among others).The server-side scripting can then be developed under the supervision of our 
                            back-end engineers to bridge the gaps between servers, connect databases, integrate business logics, and interconnect everything 
                            with the internet.
                        </p>
                    </div>
                    <div className="services_rectangle_web">
                        <h4 className="service-heading">Testing & Launch</h4>
                        <p className="service-paragraph">Once the website coding is done, we run many tests to ensure the site is free from either bugs or 
                        glitches. Our quality assurance team runs a series of function tests, usability tests, compatibility tests, and performance tests, 
                        among others, to make sure the site is functioning at its optimal efficiency.</p>
                        <p className="service-paragraph">Once it succeeds in all these quality assurance processes, it's ready for deployment onto a web app 
                        or website. We'll deploy the newly created website using FTP (File Transfer Protocol), which can be 
                        performed on GitHub, Bitbucket, FileZilla or BeanStalk.</p>
                        <p className="service-paragraph">After deployment, all that's left is to share your website with the world!</p>
                    </div>
            </Row>

            <Marginer direction="vertical" margin="5rem" />
            <Row xs="auto" md={1}>
            <div className="bttn">
                <h2>Services We Offer</h2>
                <p className='services-para'>We support you professionally and with foresight in the challenges posed
                  by technological change and progress.In doing so, we always focus on one thing-people. 
                  And thus your employees, your team. We are your partner at eye level.</p>
                  <Tab.Container id='left-tabs-example' defaultActiveKey="first">
                  <Row xs="auto" lg={2}>
                    <Col className='col-p' xs={12} md={12}>
                        <Nav variant='pills' className='tabs-link'>
                            <Nav.Item className='nav-item'>
                                <Nav.Link eventKey="first" className='navlink'>Web Design
                                  <img src={right} alt="arrow"/>
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item className='nav-item'>
                                <Nav.Link eventKey="second" className='navlink'>Web Development
                                    <img src={right} alt="arrow"/>
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item className='nav-item'>
                                <Nav.Link eventKey="Third" className='navlink'>WordPress Development
                                    <img src={right} alt="arrow"/>
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item className='nav-item'>
                                <Nav.Link eventKey="four" className='navlink'>Dedicated Programmer
                                    <img src={right} alt="arrow"/>
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item className='nav-item'>
                                <Nav.Link eventKey="fifth" className='navlink'>Mobile App Development
                                    <img src={right} alt="arrow"/>
                                </Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </Col>
                    <Col>
                    <Tab.Content>
                    <Tab.Pane eventKey="first">
                    <div className='para-div-1'>
                    <h5>Web Design</h5>
                    <p>We specialize in creating super stunning, responsive websites that look great on any device. 
                        With a well-designed site, you can draw readers in and sell to them instead of competing with 
                        other sites that offer similar products. Good impressions are necessary for getting sales and 
                        keeping your site at the top of search engines. Is setting up a digital presence something you're 
                        looking to do? Enviree can help!</p>
                    </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="second">
                    <div className='para-div-1'>
                    <h5>Web Development</h5>
                    <p>We can take your website from concept to reality with our custom coding and development services. 
                        Look no further if you're searching for the right professionals to create a powerful online presence 
                        in the digital world!</p>
                    </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="Third">
                    <div className='para-div-1'>
                    <h5>WordPress Development</h5>
                    <p>WordPress is undoubtedly one of the most powerful content management systems in the digital world. 
                        WordPress stands out because of its ease of access, simplicity, and pleasant development experience. 
                        Many WordPress developers offer development services and complete website maintenance just in case 
                        something goes wrong. With Enviree, you'll get a dedicated WordPress team working on your next big 
                        project.</p>
                    </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="four">
                    <div className='para-div-1'>
                    <h5>Dedicated Programmer</h5>
                    <p>With all the new technologies emerging, it can be challenging to decide which one is best for you. 
                        But at Enviree, highly experienced programmers will create and build a digital solution catered to 
                        your business needs. With our wide range of skills and technologies, we'll make the perfect digital 
                        product for you and your customers. We offer dedicated teams skilled in various web languages i.e. 
                        PHP, React Js, ReactNative, .Net, Cordova & Ionic.</p>
                    </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="fifth">
                    <div className='para-div-1'>
                    <h5>Mobile App Development</h5>
                    <p>When you need mobile app development services, you can turn to Enviree. Whether you're looking for a 
                        customer- or business-centric app, we offer the perfect service. We provide mobile app integration 
                        and management services and the development of customer-centric and enterprise-based solutions. 
                        We have you covered no matter what platform your app needs, Android, iOS, or Windows. We develop 
                        the best apps on the market and can support them too!</p>
                    </div>
                    </Tab.Pane>
                    </Tab.Content>
                    </Col>
                  </Row>
                  </Tab.Container>
            </div>
            </Row>
            <Marginer direction="vertical" margin="2rem" />
            <Row className="development-process-2">
            <div>
                <h1 className="dev-heading">WHY CHOOSE ENVIREE FOR YOUR WEBSITE DEVELOPMENT?</h1>
                <p className='services-para-3'>If you're searching for a web development company to help you create a beautiful and practical 
                    website, Enviree is the right choice.Here are some reasons why Enviree can help you with your web development needs:</p>
                <div className="experience-rect-1">
                    <Row xs="auto" md={3}>
                            <Col xs={12} md={12} lg={5}>
                                <Row xs={12}>
                                    <div className="inline-div">
                                        <img className="check-img" src={checkicon} alt="check icon"></img>
                                        <h5 className="experience-heading">Our Credibility</h5>
                                    </div>
                                        <p className="experience-paragraph">We have a proven track record of creating successful websites for our clients. We take our due time to 
                                            understand your specific goals and needs so that we can create a custom solution that works for you.</p>
                                        {/* <div className="line"></div> */}
                                </Row>
                                <Row xs={12}>
                                    <div className="inline-div">
                                        <img className="check-img" src={checkicon} alt="check icon"></img>
                                        <h5 className="experience-heading">Have a Result-Driven Approach</h5>
                                    </div>
                                    <p className="experience-paragraph">Our team of developers and designers work to create end-to-end solutions that 
                                        combine physical and digital spaces. This means we go above and beyond by 
                                        developing a system that creates the best experience for your customers.</p>
                                    {/* <div className="line"></div> */}
                                </Row>
                            </Col>
                            <Col md={0} lg={1}></Col>
                            <Col xs="auto" md={12} lg={6} xl={5}>
                            <Row xs={12}>
                            <div className="inline-div">
                                <img className="check-img" src={checkicon} alt="check icon"></img>
                                <h5 className="experience-heading">Offer a Wide Range Of Technologies</h5>
                            </div>
                                <p className="experience-paragraph">Whether you need 100% custom coding for a startup or existing website,
                                    multi-purpose scripts to provide dynamic, snappy responses to your 
                                    users, or back-end programming services, we've covered you. We use 
                                    various technologies, from Vue to React, Node.js and more. Our 
                                    back-end coders have significant knowledge of all the critical PHP 
                                    ecosystems. WordPress or Magento powers our CMS-based solutions, 
                                    and our server-side scripting specialists are skilled in AWS and Google 
                                    Cloud.</p>
                                {/* <div className="line"></div> */}
                                </Row>
                            </Col>
                    </Row>
                </div>
            </div>
            </Row>
            <Marginer direction="vertical" margin="2rem" />
            </Container>
         </Container>
        );    
    }
export default Services3;